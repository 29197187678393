.meals {
  &Container {
    padding: 2rem 0 0 0;
    width: 75%;
  }

  &List {
    margin: 0;
    padding: 0;
  }

  &Item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 1rem 0;

    &:first-child {
      margin-top: 0;
    }

    &Text {
      display: flex;
      flex-direction: column;
      width: 500px;
      line-height: 26px;
    }

    &Price {
      color: #000;
      font-size: 18px;
    }

    &Amount {
      align-items: center;
      display: flex;
      width: 142px;
      justify-content: center;
    }
  }
}

.mealTitle {
  color: #000;
}

.mealDescription {
  color: #ccc;
}

.categoryName {
  color: #E00000;
  font-size: 30px;
  margin: 0;
}

@media (max-width: 600px) {
  .mealsItem {
    flex-wrap: wrap;
    padding: 0 1rem;
  }

  .mealsItemText {
    width: 100%;
  }
}


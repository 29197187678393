@import '../../../../config';

.wrapper {
  background: #ecf0f1 url('../../../../img/cubes.png');
  height: 100%;
  width: 100%;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.loginBox {
  background: rgba(255, 255, 255, .5);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
}

.title {
  font-weight: 400;
}

.inputContainer {
  width: 100%;
}

.input {
  background: rgba(0, 0, 0, 0);
  margin: 0 0 1.5rem 0;
}

.loginError {
  color: $primary-color;
  margin: 0 0 1rem 0;
}
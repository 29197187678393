.mealAction {
  background-image: linear-gradient(-179deg, #FFFFFF 25%, #FCFCFC 100%) !important;
  box-shadow: 0 1px 4px 0 #E8E8E8, 0 2px 5px 0 rgba(0,0,0,0.11) !important;
  border-radius: 100px !important;
  color: #FF6269 !important;
  font-size: 18px !important;
  padding: 5px !important;
}

.mealActionIcon {
  font-size: 18px !important;
}

.disabledMealAction {
  .mealActionIcon {
    color: #ccc !important;
  }
}

.mealAmount {
  margin: 0 1rem;
  font-size: 18px;
  display: inline-block;
  width: 30px;
  text-align: center;
}
.selectContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  position: relative;
  width: 25%;
}

.selectLabel {
  color: #999;
  font-size: 13px;
}

.select {
  background: none;
  border: none;
  border-bottom: 1px solid red;
  border-radius: 0 !important;
  font-weight: 300;
  height: 35px;
  font-size: 1rem;
  padding: 0 0 0 2px;
  -webkit-appearance: none;
}

.selectArrow {
  bottom: 5px;
  position: absolute;
  right: 5px;
}

@media (max-width: 600px) {
  .selectContainer {
    width: 80%;
  }
}
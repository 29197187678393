.loaderContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.noDrinksText {
  margin: 2rem 0 !important;
}

.headerContainer {
  justify-content: space-between;
  display: flex;
}

.headerSwitch {
  align-self: center !important;
}

.drinksList {
  padding: 0;
  margin: 0;
}

.drinkItem {
  align-items: center;
  display: flex;
  list-style: none;
}

.textInputContainer {
  margin: 0 1rem;
  width: 100%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.drinkSwitch {
  margin: 1.5rem 0 0 0 !important;
}

.drinkRemoveBtn {
  margin: 1.5rem 0 0 0 !important;
}

.btnsContainer {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 0 0;
}

.cancelBtn {
  margin: 0 1rem 0 0 !important;
}
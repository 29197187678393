@import '../../../../../config';

.sidebarFixed {
  position: fixed !important;
  top: 0;
}

.sidebar {
  position: relative;

  &Container {
    padding: 0 0 0 100px;
    width: 25%;
  }

  &Button {
    max-width: 220px;
    width: 80%;
  }

  &ButtonMargin {
    margin: 2rem 0 .75rem !important;
  }

  /**
  &Button {
    background: rgba(0, 0, 0, 0);
    border: 1px solid #919191;
    color: #919191;
    cursor: pointer;
    display: block;
    font-size: 14px;
    max-width: 125px;
    margin: 0 0 15px;
    padding: .75rem;
    text-align: center;
  }
  **/

  &Actions {
    margin: 42px 0;
  }

  &Opening {
    margin: 50px 0 0 0;
    font-size: 14px;
    max-width: 125px;
    text-align: left;

    &Title {
      color: $primary-color;
      font-weight: 600;
      letter-spacing: .5px;
    }

    &Time {
      align-items: center;
      color:$gray-color;
      display: flex;
      margin: .5rem 0 0 0;
    }

    &TimeIcon {
      color:$gray-color !important;
      margin: 0 .25rem 0 0;
    }
  }

  &CategoriesList {
    margin: auto;
    padding: 0;
  }

  &CategoriesItem {
    list-style: none;
    padding: 5px 0;
    font-weight: 600;
  }
}

.sidebarCategoryLink {
  cursor: pointer;
}

@media (max-width: 600px) {
  .sidebarContainer {
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .sidebarOpening {
    margin: 50px auto 0;
  }
}
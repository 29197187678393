.wrapper {
  background: url('./img/bg.jpeg') no-repeat fixed;
  background-size: cover;
  // box-shadow: inset 0px 70px 140px rgba(0,0,0,.4);
  position: relative;
  height: 100%;
  width: 100vw;
}

.container {
  height: 100%;
  max-width: 1200px;
  margin: auto;
  min-height: 100vh;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 5rem;
  padding: 2rem 0 0 0;
  box-sizing: border-box;
  height: 15%;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.headerText {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 0;
  padding: 0 .5rem;
  text-align: right;
  text-shadow: 2px 2px 5px #000;

  a {
    color: #fff;
  }
}

.mobileBackground {
  display: none;
}

@media (max-width: 600px) {
  .wrapper {
    background: none;
  }

  .mobileBackground {
    background: url('./img/bg.jpeg') no-repeat center 0;
    display: block;
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: -5;
  }

  .header {
    flex-direction: column;
    height: auto;
    margin: 0;
  }

  .headerTextContainer {
    align-items: center;
  }

  .headerText {
    text-align: center;
  }
}

.restaurantsContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 85%;
}

.restaurantsList {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0;
}

.restaurantsItem {
  letter-spacing: 2px;
  list-style: none;
  margin: 30px 0;
}


// TODO: Export this to button component
.disabledButton {
  background: #BDBDBD !important;
  cursor: default !important;

  &:hover {
    background: lighten(#BDBDBD, 5%) !important;
  }
}

@media (max-width: 600px) {
  .restaurantsContainer {
    height: initial;
  }

  .restaurantsItem {
    width: 90%;
    box-sizing: border-box;
  }
}
@import '../../../../config';

.container {
  box-sizing: border-box;
  width: 100%;
}

.restaurantList {
  margin: 0;
  padding: 0;
}

.restaurantItem {
  align-items: center;
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0 1rem;
  position: relative;

  &:hover {
    background: darken(#fff, 10%);
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:not(:last-child) {
    &:before {
      background: lighten($gray-color, 30%);
      bottom: 0;
      content: '';
      height: 1px;
      left: 2.5%;
      position: absolute;
      width: 95%;
    }
  }
}

.loaderContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.changeStateContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 0 .5rem 0;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    text-align: center;
  }
}

.changeStateButtonsContainer {
  padding: 1rem 0;
}

.changeStateButton {
  cursor: pointer;
  text-align: right;
  padding: 0 1rem;

  &:hover {
    color: lighten(#000, 40%);
  }
}

.addRestaurantButton {
  color: #000;

  &:hover {
    color: lighten(#000, 50%);
  }
}

.restaurantOrderButton {
  padding: 0 .25rem;

  &:hover {
    color: lighten(#000, 50%) !important;
  }
}

.restaurantName {
  width: 75%;
}

.restaurantControls {
  @media only screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

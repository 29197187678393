.mainContainer {
  display: flex;
  max-width: 1200px;
  margin: auto;
}

@media (max-width: 600px) {
  .mainContainer {
    flex-direction: column;
    padding-bottom: 80px;
  }
}
.badge {
  color: #000 !important;
  top: auto !important;
  bottom: 5px !important;
  left: 7px !important;
  background: #fff !important;
  border: 1px solid #999 !important;
  height: 18px !important;
  width: 18px !important;
}

.basketIconButton {
  //font-size: 2rem;
}

.basketIcon {
  font-size: 2rem !important;
}
.meals {
  &Container {
    padding: 2rem 0 0 0;
    width: 75%;
  }

  &List {
    margin: 0;
    padding: 0;
  }

  &Item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: .5rem 0;

    &Text {
      display: flex;
      flex-direction: column;
      width: 500px;
      line-height: 26px;
    }

    &Price {
      color: #000;
      font-size: 18px;
    }

    &Amount {
      align-items: center;
      display: flex;
    }
  }
}

.mealTitle {
  color: #000;
}

.mealDescription {
  color: #ccc;
}

.mealAction {
  background-image: linear-gradient(-179deg, #FFFFFF 25%, #FCFCFC 100%) !important;
  box-shadow: 0 1px 4px 0 #E8E8E8, 0 2px 5px 0 rgba(0,0,0,0.11) !important;
  border-radius: 100px !important;
  color: #FF6269 !important;
  font-size: 18px !important;
  padding: 5px !important;
}

.disabledMealAction {
  .mealAction {
    color: #ccc !important;
  }
}

.mealAmount {
  margin: 0 .5rem;
  font-size: 18px;
}

.categoryName {
  color: #E00000;
  font-size: 30px;
  margin: 0 0 1rem 0;
}

@media (max-width: 600px) {
  .categoryName {
    text-align: center;
  }

  .mealsContainer {
    width: 100%;
  }

  .mealSubcontainer {
    width: 100%;
  }
}
.textContainer {
    margin: 1rem 0 0 0;
  }
  
.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0 0 0;
}

.loaderContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.enabledText {
    display: inline;
}
@import '../../../config';

.inputContainer {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  width: 25%;
}

.inputTextarea {
  resize: none;
  min-height: 50px;
}

.inputField {
  border: none;
  border-bottom: 1px solid #E00000;
  color: $input-color;
  height: 2rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  width: 100%;
  border-radius: 0;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: lighten($input-color, 75%);
  }
}

.inputLabel {
  font-size: 13px;
  color: #999;
}

.hint {
  border-bottom: 1px solid darken(#fff, 5%);
  cursor: pointer;
  display: block;
  padding: .33rem 0 .33rem .5rem;
}

.hintHighlighted {
  background: darken(#fff, 10%);
}

.inputHelperText {
  color: rgba(0, 0, 0, .75);
  font-size: 13px;
  margin-top: .5rem;
}

@media (max-width: 600px) {
  .inputContainer {
    width: 80%;
  }
}
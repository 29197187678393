.recapOrderTitle {
  color: #E00000;
  display: flex;
  align-items: center;
  margin: 3rem 0 0 0;
}

.recapOrderTitleText {
  font-size: 30px;
  margin: 0 0 0 1rem;
}

.recapOrderTitleIcon {
  font-size: 3rem !important;
}

@media (max-width: 600px) {
  .recapOrderTitle {
    text-align: center;
  }

  .recapOrderTitleIcon {
    display: none;
  }
}
@import '../../../config';

.root {
  background-image: linear-gradient(-123deg, #BD002C 11%, #E6002F 78%) !important;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 9px 24px 0 rgba(189,0,0,0.27) !important;
  border-radius: 2px !important;
  min-height: 48px !important;

  &:hover {
    background-image: linear-gradient(-123deg, lighten(#BD002C, 5%) 11%, lighten(#E6002F, 5%) 78%) !important;
  }
}

.label {
  color: #fff !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  letter-spacing: 1px !important;
}

.smButton {
  height: 2rem !important;
}

.mdButton {
  height: 3rem !important;
}

.lgButton {
  height: 4rem !important;
  min-width: 30rem !important;
}

.xlButton {
  height: 5rem !important;
  min-width: 35rem !important;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22) !important;
}

.modal {
  background: #fff !important;
  border: 1px solid #E8E8E8 !important;
  box-shadow: none !important;

  &:hover {
    background: darken(#fff, 5%) !important;
    border: 1px solid #E8E8E8 !important;
  }
}

.modalLabel {
  color: $gray-color !important;
}

@media (max-width: 600px) {
  .xlButton {
    min-width: 100% !important;
  }
}
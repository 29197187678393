.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.recapContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.recapOrderList {
  margin: 1rem 0 0 0;
  padding: 0;
  width: 70%;
}

.detailsContainer {
  width: 100%;
}




///

.meals {
  &Container {
    padding: 2rem 0 0 0;
    width: 75%;
  }

  &List {
    margin: 0;
    padding: 0;
  }

  &Item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: .5rem 0;
    min-height: 50px;

    &Text {
      display: flex;
      flex-direction: column;
      width: 500px;
      line-height: 26px;
    }

    &Price {
      color: #000;
      font-size: 18px;
    }

    &Amount {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 142px;
    }
  }
}

.orderEmpty {
  font-size: 2rem;
  margin: 3rem 0 0 0;
  text-align: center;
}

@media (max-width: 600px) {
  .mealsItemText {
    width: 100px;
  }

  .mealsItemAmount {
    width: 60px;
  }

  .recapOrderList {
    width: 90%;
  }

  .inputContainer {
    width: 80%;
  }
}


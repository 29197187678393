.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
}

.titleContainer {
  align-items: center;
  color: #E00000;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-size: 30px;
  text-align: center;
}

.icon {
  font-size: 3rem !important;
  margin: 0 .5rem 0 0;
}

.text {
  text-align: center;
}

@media (max-width: 600px) {
  .titleContainer {
    flex-direction: column;
  }
}


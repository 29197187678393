.buttonContainer {
  margin: 2.5rem 0;
}

.submitButton {
  min-width: 224px !important;
}

.detailsForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.inputContainer {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  width: 25%;
}

@media (max-width: 600px) {
  .inputContainer {
    width: 80%;
  }
}
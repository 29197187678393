@import '../../../config';

.unauthorizedAccessContainer {
  align-items: center;
  color: $gray-color;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 2rem 0 0 0;
  width: 100%;
}

.unauthorizedAccessText {
  margin: .25rem 0;
  font-size: 1.5rem;
}

.unauthorizedAccessIcon {
  font-size: 2rem;
  margin: 0 .5rem 0 0;
}
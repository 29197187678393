.textContainer {
  margin: 1rem 0 0 0;
}

.textInput {
  height: 250px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 0 0;
}

.loaderContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
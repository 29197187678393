.mobileMenu {
  display: none;
}

@media (max-width: 600px) {
  .mobileMenu {
    background: #fff;
    border-top: 1px solid #eee;
    bottom: 0;
    display: flex;
    left: 0;
    padding: 0;
    position: fixed;
    margin: 0;
    width: 100%;
    z-index: 99999999;
  }

  .mobileMenuItem {
    list-style: none;
    padding: 1rem 0;
    text-align: center;
    width: 33%;

    &:not(:last-child) {
      border-right: 1px solid #eee;
    }
  }

  .mobileMenuIcon {
    font-size: 2rem;
  }
}
.imageSection {
  align-items: flex-end;
  background: url('../../../img/bg.jpeg') no-repeat center center;
  display: flex;
  height: 250px;
  padding: 0 0 2rem 8.5rem;
  position: relative;
}

.imageOverlay {
  background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, #000 100%);
  height: 100%;
  left: 0;
  position: absolute;
  opacity: .8;
  top: 0;
  width: 100%;
}

.imageText {
  color: #fff;
  font-size: 35px;
  margin: 0;
  z-index: 999;
}

.loadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  display: inherit;
  margin: inherit;
  width: 100%;
}

@media (max-width: 600px) {
  .contentContainer {
    display: flex;
    flex-direction: column;
  }
}
.courierContact {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;

  &Link {
    color: #fff;
    padding: 0 0 0 .5rem;
  }

  &Shadow {
    text-shadow: 2px 2px 5px #000;
  }
}
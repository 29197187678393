.container {
  align-items: center;
  display: flex;
  padding: 0 1rem;
}

.logo {
  &Link {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 10px 0 0 1rem;
    order: 3;
  }

  &Image {
    align-self: center;
  }
}

.logoText {
  color: #fff;
  font-family: 'Caveat', sans-serif;
  font-size: 27px;
}

.icon {
  height: 30px;
  margin: 0 .25rem;
  fill: #fff;
}

.fb_icon {
  order: 2;
}

.ig_icon {
  order: 1;
}

@media (max-width: 600px) {
  .logoLink {
    order: 2;
    padding: 10px 1rem 0 1rem;
  }

  .fb_icon {
    order: 3;
  }

  .ig_icon {
    order: 1;
  }
}

.wrapper {
  background: #ecf0f1 url('../../../img/cubes.png');
  height: auto;
  width: 100%;
}

.container {
  display: flex;
  height: auto;
  min-height: 100vh;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.logo {
  @media only screen and (max-width: 700px) {
    justify-content: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 3rem;

  @media only screen and (max-width: 700px) {
    box-sizing: border-box;
    padding: 1rem;
  }
}

.sidebar {
  background: #2c3e50;
  padding: 1rem 0 0 0;
  max-width: 200px;
  width: 30%;

  @media only screen and (max-width: 700px) {
    width: 100%;
    max-width: none;
    padding: 1rem 0;
  }
}

.menu {
  margin: 2rem 0 0 0;
}

.menuList {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.menuItem {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: 300;
  list-style: none;
  margin: .5rem 0;
}

.menuItemText {
  display: inline-block;
  padding: 0 0 0 1rem;
  width: 100px;
}

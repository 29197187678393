.orderTitle {
  color: #2c3e50;
  font-weight: 600;
}

.orderSubtitle {
  color: #2c3e50;
  font-weight: 400;
}

.orderPrice {
  color: #E00000;
  font-weight: 400;
}

.header {
  align-items: center;
  background-image: linear-gradient(-123deg, #BD002C 11%, #E6002F 78%);
  display: flex;
  justify-content: center;
  width: 100%;

  &Content {
    align-self: center;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    min-height: 100px;
    width: 100%;
  }

  &Sidebar {
    align-items: center;
    color: #fff;
    display: flex;
  }

  &Link {
    color: #fff;
  }

  &Icon {
    color: #fff;
    margin: 0 0 0 1rem;
    font-size: 2rem;
  }
}

.imageSection {
  align-items: flex-end;
  background: url('../../../../../img/bg.jpeg') no-repeat center center;
  display: flex;
  height: 250px;
  padding: 0 0 2rem 8.5rem;
  position: relative;
}

.imageOverlay {
  background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, #000 100%);
  height: 100%;
  left: 0;
  position: absolute;
  opacity: .8;
  top: 0;
  width: 100%;
}

.imageText {
  color: #fff;
  // font-family: 'Dancing Script', sans-serif;
  font-size: 35px;
  margin: 0;
  z-index: 999;
}

.link {
  z-index: 9999999;
}

.badge {
  color: #000 !important;
  bottom: 2px !important;
  top: initial !important;
  left: 10px !important;
  background: #fff !important;
  border: 1px solid #999 !important;
  height: 18px !important;
  width: 18px !important;
}

@media (max-width: 600px) {
  .headerContent {
    align-items: center;
    flex-direction: column;
    margin: 1rem 0 0 0;
  }

  .linkContainer {
    display: none;
  }

  .basketLink {
    display: none;
  }

  .imageSection {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
}
:global(html),
:global(body),
:global(#root) {
  font-family: 'Open Sans', sans-serif;
  height: auto;
  margin: 0;
  min-height: 100%;
}

:global(a) {
  text-decoration: none;
}

:global(input) {
  border-radius: 0;
  -webkit-appearance: none;
}

:global(div[role="dialog"]) {
  z-index: 9999999999;
}
@import '../../../../config';

%input {
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
  font-size: 1rem;

  &:hover {
    border-bottom: 1px solid $primary-color;
  }

  &:focus {
    border-bottom: 1px solid $primary-color;
  }

  &:focus {
    outline: none;
  }
}

.restaurantTitle {
  font-weight: 400;
  margin: 0 0 1rem 0;
  text-align: center;
}

.loaderContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
}

.restaurantName {
  @extend %input;

  font-size: 22px;
  padding: .5rem;
  text-align: center;
  width: 100%;
}

.restaurantInputLabel {
  display: inline-block;
  text-align: left;
  width: 10rem;
}

.restaurantEmail, .restaurantExternalLink {
  @extend %input;
  width: 15rem;
  margin: 0 0 0 1rem;
  text-align: center;

  @media only screen and (max-width: 700px) {
    margin: 1rem 0 0 0;
    width: 100%;
  }
}

.emailContainer {
  margin: 1rem 0 0 0;

  @media only screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 700;
    }
  }
}

.dayName {
  display: inline-block;
  width: 150px;
}

.openingHoursInput {
  @extend %input;
  width: 60px;
  text-align: center;
  margin: 0 .5rem;
}

.openingHoursItem {
  margin: .5rem 0 0 0;
}

.categoryInput {
  @extend %input;

  width: 25%;
  margin: 0 0 0 1rem;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
}

.categoryList {
  padding: 0;
  margin: 0;
}

.categoryItem {
  list-style: none;
}

.categoryName {
  display: flex;
  align-items: center;
}

.categoryExpandIcon {
  @extend .restaurantIcon;
  margin: 0 1rem 0 0;
}

.mealList {
  padding: 1rem 0;
}

.mealItem {
  align-items: center;
  display: flex;
  list-style: none;
  border-bottom: 1px solid #000;

  &:first-child {
    border-top: 1px solid #000;
  }

  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
}

.mealInput {
  @extend %input;
  display: inline-block;
  margin: .5rem 1rem;
  width: 33%;

  @media only screen and (max-width: 700px) {
    width: 100%;
    margin: .5rem 0;
  }
}

.blockTitle {
  font-weight: 700;
}

.saveButton {
  margin: 1rem 0 !important;
}

.restaurantIcon {
  cursor: pointer;

  &:hover {
    color: lighten(#000, 50%) !important;
  }

  @media only screen and (max-width: 700px) {
    order: -2;
  }
}

.addCategoryContainer {
  align-items: flex-end;
  display: flex;
  margin: 1rem 0 0 0;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
}

.addCategoryInput {
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 1px solid $primary-color;
  font-size: 1rem;
  padding: .5rem;
  width: 300px;

  @media only screen and (max-width: 700px) {
    width: 100%;
    box-sizing: border-box;
  }
}

.addMealContainer {
  align-items: flex-end;
  display: flex;
  margin: 0 0 1rem 0;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.addMealInput {
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 1px solid $primary-color;
  font-size: 1rem;
  padding: .5rem;
  width: 200px;
  margin: 0 .5rem 0 0;

  @media only screen and (max-width: 700px) {
    width: 100%;
    margin: .5rem 0 0 0;
    box-sizing: border-box;
  }
}

.addMealButton {
  background: $primary-color;
  color: #fff;
  cursor: pointer;
  margin: 0 0 0 1rem;
  padding: .5rem;
  
  @media only screen and (max-width: 700px) {
    margin: 1rem 0 0 0;
    width: 100%;
    text-align: center;
    align-self: initial;
    box-sizing: border-box;
  }
}

.addCategoryButton {
  background: $primary-color;
  color: #fff;
  cursor: pointer;
  margin: 0 0 0 1rem;
  padding: .5rem;
  
  @media only screen and (max-width: 700px) {
    align-self: flex-end;
    margin: 1rem 0 0 0;
  }
}

.deleteCategoryIcon {
  @extend .restaurantIcon;
  margin: 0 0 0 1rem;
}

.deleteMealIcon {
  @extend .restaurantIcon;
  margin: 0 0 0 1rem;

  @media only screen and (max-width: 700px) {
    order: -2;
    margin-right: auto;
  }
}

.mealDisabledButton {
  @media only screen and (max-width: 700px) {
    order: -2;
  }
}
